.checkbox-grid li {
    display: block;
    float: left;
    width: 20%;
    /* min-width: 100px; */
}

.columns.is-flex-wrap-wrap .flex-basis-50 {
    flex-basis: 50%;
}
.columns.is-flex-wrap-wrap .flex-basis-33 {
    flex-basis: 33%;
}
.columns.is-flex-wrap-wrap .flex-basis-25 {
    flex-basis: 25%;
}

.columns.is-flex-wrap-wrap .flex-basis-20 {
    flex-basis: 20%;
}

ul.columns.is-flex-wrap-wrap>li, ul.columns.is-flex-wrap-wrap > li > label {
    display: flex;
}