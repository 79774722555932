/* ------------------------------------------------------- */
/* Bulma Helpers */
.sidebar-overlay {
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  width: calc(100vw - 67px);
  height: 100%;
  z-index: -1;
}

.full-height {
  height: 100%
}

.menu-list  a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

/********************************TABLES******************************/
table {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}

/******************************TABLE FLIP****************************/
tr.flip-table {
  display: block;
  white-space: normal;
  float: left;
}

.flip-table td, .flip-table th {
  display: block;
}


.main-area{
  /* width: calc(100vw - 67px); */
  /* max-height: calc(100vh - 40px); */

  padding: 10px;
  /* float: left; */
  margin: 0;
}

/* React modal overlay was not on top of select::after elements. */

.ReactModal__Overlay {
    z-index: 1000;
}

/* ------------------------------------------------------- */

element {
  --button-corner-radius: 0pt;
}

html,
body {
  margin: 0px;
  /* background-color: #adaaad; */
  background-color: #ddd  ;
}
.app {
  height: 100%;
}


/* ------------------------------------------------------- */
/* Buttons. */

.button:not(:last-child),
.button-container:not(:last-child) {
  margin-right: 10px;
  display: inline-block;
}

.button-one-holder {
  display: table-cell;
  width: 28%;
  vertical-align: middle;
}

.div-28 {
  margin: auto;
  width: 28%;
  padding: 1%;
  vertical-align: middle;
  align: center;
}
.div-80 {
  margin: auto;
  width: 80%;
  padding: 1%;
  vertical-align: middle;
  align: center;
}
.div-50 {
  margin: auto;
  width: 50%;
  padding: 1%;
  vertical-align: middle;
  align: center;
}

.button-one {
  border-radius: var(--button-corner-radius);
  width: 96%;
  padding: 1% 2% 1% 2%;
  border-style: solid;
  border-width: 2px;
  border-color: #bbbbbb;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  text-align: center;
  color: #555555;
  background-color: #cccccc;
  line-height: 20pt;
  vertical-align: middle;
  transition: background-color 200ms;
  cursor: pointer;
}
.button-one:hover {
  background-color: #aaaaaa;
}
.button-one12 {
  width: 80%;
  border-radius: var(--button-corner-radius);
  padding: 5% 10% 5% 10%;
  border-style: solid;
  border-width: 2px;
  border-color: #bbbbbb;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  color: #555555;
  background-color: #cccccc;
  line-height: 12pt;
  transition: background-color 200ms;
  cursor: pointer;
}
.button-one12-disabled {
  width: 80%;
  border-radius: var(--button-corner-radius);
  padding: 5% 10% 5% 10%;
  border-style: solid;
  border-width: 2px;
  border-color: #bbbbbb;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  color: #555555;
  background-color: #cccccc;
  line-height: 12pt;
  transition: background-color 200ms;
  pointer-events: none;
  opacity: 0.5;
  cursor: pointer;
}
.button-one12:hover {
  background-color: #aaaaaa;
}
.button-one12-red {
  width: 80%;
  border-radius: var(--button-corner-radius);
  padding: 5% 10% 5% 10%;
  border-style: solid;
  border-width: 2px;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  background-color: #dd0000;
  border-color: #ee0000;
  line-height: 12pt;
  transition: background-color 200ms;
  cursor: pointer;
}
.button-one12-red:hover {
  background-color: #ff0000;
}

/* Displays a "Coppied!" tooltip popup when clicking passwords on the Agents tab */
.copy-text:active {
  color: #00947e;
}
.copy-text.clicked::before {
  content: "Copied!";
  position: absolute;
  background-color: #ebfffc;
  color: #00947e;
  top: -2em;
  left: 1em;
  background: aliceblue;
  padding: 7px 15px;
  border-radius: 7px;
  opacity: 0;
  transform: translateY(20px);
  animation: slide-up 300ms forwards;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ------------------------------------------------------- */
/* The dcm stuff. */

.dcm {
  width: 100%;
  height: 100%;
}
.dcm-topbar {
  width: 100%;
  height: 10%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: #5555ee;
  z-index: 1;
}
.dcm-topbar-left {
  width: 65%;
  height: 100%;
  margin: 0;
  padding: 0;
  padding-left: 5%;
  top: 0;
  left: 0;
  font-size: 5vh;
  font-family: Ariel, sans-serif;
  color: #ffffff;
  background-color: #555555;
  float: left;
  line-height: 10vh;
}
.dcm-topbar-right {
  width: 25%;
  height: 100%;
  margin: 0;
  padding: 0px;
  padding-right: 5%;
  top: 0;
  left: 0;
  font-size: 3vh;
  font-family: Arial, sans-serif;
  color: #ffffff;
  background-color: #555555;
  text-align: right;
  float: right;
  line-height: 10vh;
  cursor: pointer;
  transition: color 15ms;
  position: relative;
}
.dcm-topbar-right:hover {
  color: #dddddd;
}

.dropbtn {
  background-color: #555555;
  color: white;
  height: 100%;
  font-size: 3vh;
  font-family: Arial, sans-serif;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
}

/* Links inside the dropdown */
.dropdown-content a {
  font-size: 1em;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  color: #dddddd;
}

/* ------------------------------------------------------- */
/* The mainscreen stuff. */

.dcm-mainscreen {
  width: 80%;
  height: 100%;
  margin-left: 20%;
  padding: 0;
  top: 0;
  left: 0;
  font-size: 100%;
  font-family: Ariel, sans-serif;
  color: #000000;
  background-color: #ffffff;
}
.dcm-mainscreen-submenu {
  width: 100%;
  margin-top: 10px;
  height: 10%;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
}
.dcm-mainscreen-campaign-name {
  width: 98%;
  height: 150px;
  padding: 0% 0% 0% 2%;
  line-height: 64px;
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  color: #000000;
  background-color: #eeeeee;
  text-align: left;
}

/* ------------------------------------------------------- */
/* The mainscreen script stuff. */

.dcm-mainscreen-script {
  width: 90%;
  /* z-index: 6; */
  position: relative;
  background-color: #ffffff;
  display: table;
}
.dcm-mainscreen-script-editmode {
  width: 90%;
  /* z-index: 6; */
  position: relative;
  background-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color: #000000;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
}
.dcm-mainscreen-script-note {
  width: 96%;
  min-height: 24pt;
  padding: 2%;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #dddddd;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  vertical-align: middle;
  background-color: #eeeeee;
}
.dcm-mainscreen-script-item {
  font-weight: 500;
  margin: 8px 0;
}
.dcm-mainscreen-script-item-editable {
  width: 80%;
  min-height: 24pt;
  padding: 1%;
  border-style: solid;
  border-width: 2px;
  font-size: 16pt;
  border-color: #eeeeee;
  font-family: Ariel, sans-serif;
  vertical-align: middle;
  display: inline-block;
}
.dcm-mainscreen-script-item-editable:focus {
  outline: none;
  border-style: solid;
  border-width: 2px;
  border-color: #999999;
}

.dcm-mainscreen-script-item-id {
  border-bottom: 2px solid lightgrey;
  margin-top: 20px;
  font-size: 14pt;
  font-weight: bold;
  font-family: monospace;
}
.dcm-mainscreen-script-button {
  width: 36pt;
  padding: 1%;
  height: 24pt;
  border-style: solid;
  border-width: 1px;
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.dcm-mainscreen-script-p-button {
  color: #aaaaaa;
  background-color: #eeeeee;
  width: 24pt;
  margin: 1%;
  margin-left: 90%;
  height: 24pt;
  border-style: solid;
  border-width: 2px;
  border-color: #aaaaaa;
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  line-height: 21pt;
  transition: background-color 200ms;
  float: right;
  cursor: pointer;
}
.dcm-mainscreen-script-p-button:hover {
  background-color: #999999;
}
.dcm-mainscreen-script-m-button {
  color: #aaaaaa;
  background-color: #eeeeee;
  width: 24pt;
  margin: 1%;
  height: 24pt;
  border-style: solid;
  border-width: 2px;
  border-color: #aaaaaa;
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  line-height: 21pt;
  transition: background-color 200ms;
  float: right;
  cursor: pointer;
}
.dcm-mainscreen-script-m-button:hover {
  background-color: #999999;
}
.dcm-mainscreen-script-editsavebutton {
  margin: 1%;
  height: 24pt;
  width: 64pt;
  border-style: solid;
  border-width: 1px;
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  cursor: pointer;
}

.question-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.question-header * + * {
  margin-left: 5px;
}

.question-header .textarea {
  min-width: auto; /* fit with question-id */
  padding-top: 5px;
  padding-bottom: 5px;
}

input.question-id {
  font-weight: bold;
  flex-basis: 100%;
}

input.question-id:not(:focus) {
  border-color: transparent;
  box-shadow: none;
}

.help .sample-label {
  font-weight: bold;
}

.help.warn,
.help .warn {
  color: red;
  font-weight: bold;
}

.charsleft:not(.warn) {
  display: none;
}


/* ------------------------------------------------------- */
/* The mainscreen login stuff. */

.dcm-mainscreen-login-title {
  width: 100%;
  margin: 0;
  height: 24pt;
  display: inline-block;
  vertical-align: middle;
  background-color: #555555;
  color: #ffffff;
}
.dcm-mainscreen-login-row {
  width: 100%;
  margin: 0;
  height: 24pt;
  line-height: 24pt;
  border-style: solid;
  border-color: #aaaaaa;
  border-width: 0px 0px 1px 0px;
  display: inline-block;
  vertical-align: middle;
}
.dcm-mainscreen-login-row-id {
  width: 40%;
  float: left;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  margin-left: 5%;
  vertical-align: middle;
  height: 24pt;
  line-height: 24pt;
}
.dcm-mainscreen-login-row-password {
  width: 60%;
  margin-left: 40%;
  text-align: left;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  vertical-align: middle;
  height: 24pt;
  line-height: 24pt;
}

/* ------------------------------------------------------- */
/* The mainscreen list stuff. */

.dcm-mainscreen-list-holder {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.dcm-mainscreen-list-title {
  width: 100%;
  margin: 0;
  height: 24pt;
  vertical-align: middle;
  background-color: #555555;
  color: #ffffff;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
}
.dcm-mainscreen-list-note {
  width: 100%;
  margin: 0;
  height: 24pt;
  display: inline-block;
  vertical-align: middle;
  background-color: #999999;
  color: #ffffff;
  line-height: 24pt;
  padding-left: 5%;
}
.dcm-mainscreen-list-row {
  width: 100%;
  margin: 0;
  height: 24pt;
  line-height: 24pt;
  border-style: solid;
  border-color: #aaaaaa;
  border-width: 0px 0px 1px 0px;
  display: inline-block;
  vertical-align: middle;
}
.dcm-mainscreen-list-row-name {
  width: 35%;
  float: left;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  margin-left: 5%;
  vertical-align: middle;
  height: 24pt;
  line-height: 24pt;
}
.dcm-mainscreen-list-row-phone {
  width: 20%;
  float: left;
  text-align: left;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  vertical-align: middle;
  height: 24pt;
  line-height: 24pt;
}
.dcm-mainscreen-list-row-senderid {
  width: 20%;
  float: left;
  text-align: left;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  vertical-align: middle;
  height: 24pt;
  line-height: 24pt;
}
.dcm-mainscreen-list-row-status {
  width: 20%;
  float: left;
  text-align: left;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  vertical-align: middle;
  height: 24pt;
  line-height: 24pt;
}

/* ------------------------------------------------------- */
/* The mainscreen data stuff. */

.dcm-mainscreen-data {
  width: 100%;
}
.dcm-mainscreen-data-chartholder {
  width: 60%;
  margin: 0 auto;
}
.dcm-mainscreen-data-label {
  width: 60%;
  margin: 0 auto;
  font-size: 24pt;
  font-family: Ariel, sans-serif;
}
.dcm-mainscreen-data-square {
  width: 24pt;
  height: 24pt;
  margin-right: 4pt;
  display: inline-block;
}
/* ------------------------------------------------------- */
/* Tools */

.dcm-mainscreen-tools {
}

.dcm-mainscreen-tools-label {
  width: 92%;
  margin: 2%;
  padding: 2%;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--button-corner-radius);
  border-color: #aaaaaa;
}
.dcm-mainscreen-tools-button {
  margin: 5pt;
  height: 24pt;
  border-style: solid;
  border-width: 1px;
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.dcm-mainscreen-tools-rename-modal {
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  position: fixed;
  width: 400px;
  margin: -100px 0 0 -200px;
  padding: 1%;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--button-corner-radius);
  border-color: #aaaaaa;
  background-color: #ffffff;
  z-index: 10;
  -webkit-transform: translateZ(0);
  align: center;
  text-align: center;
}

.dcm-mainscreen-tools-item {
  width: 85%;
  min-height: 24pt;
  margin: auto;
  border-style: solid;
  border-width: 0px;
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  display: inline-block;
  vertical-align: middle;
  background-color: #eeeeee;
  align: center;
}

/* ------------------------------------------------------- */
/* */

.dcm-mainscreen-submenu-item-selected {
  width: 15%;
  float: left;
  text-align: center;
  padding: 2px;
  border-bottom: 4px solid #bbbbbb;
  cursor: pointer;
}
.dcm-mainscreen-submenu-item-unselected {
  width: 15%;
  float: left;
  padding: 2px;
  text-align: center;
  border-bottom: 4px solid #ffffff;
  transition: border-bottom 300ms;
  cursor: pointer;
}
.dcm-mainscreen-submenu-item-unselected:hover {
  border-bottom: 4px solid #eeeeee;
}

/* ------------------------------------------------------- */
/* Left bar. */

.dcm-leftbar {
  float: left;
  width: 18%;
  height: 100%;
  margin: 0;
  padding: 1%;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  color: #ffffff;
  background-color: #adaaad;
  z-index: 1;
}
.dcm-leftbar-item-unselected {
  padding: 5% 10% 5% 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5%;
  margin-right: 5%;
  width: 70%;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  color: #000000;
  background-color: #ffffff;
  transition: margin-left 200ms;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dcm-leftbar-item-unselected:hover {
  padding: 5% 10% 5% 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10%;
  margin-right: 5%;
  width: 70%;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer;
}
.dcm-leftbar-item-selected {
  padding: 5% 10% 5% 10%;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 15%;
  margin-right: 0%;
  width: 70%;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dcm-leftbar-item-addnew {
  padding: 5% 10% 5% 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5%;
  margin-right: 5%;
  width: 70%;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
  line-height: 16pt;
  border-style: dashed;
  border-width: 4px;
  border-color: #ffffff;
  color: #ffffff;
  background-color: #aaaaaa;
  text-align: center;
  vertical-align: middle;
  transition: background-color 200ms;
  cursor: pointer;
}
.dcm-leftbar-item-addnew:hover {
  background-color: #888888;
}

.dcm-mainscreen-tools-yesno-modal {
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  position: fixed;
  width: 400px;
  margin: -100px 0 0 -200px;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--button-corner-radius);
  border-color: #aaaaaa;
  background-color: #ffffff;
  z-index: 10;
  -webkit-transform: translateZ(0);
  align: center;
  text-align: center;
}

.dcm-mainscreen-tools-copycampaign-modal {
  position: fixed;
  width: 400px;
  margin: -100px 0 0 -200px;
  padding: 1%;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--button-corner-radius);
  border-color: #aaaaaa;
  background-color: #ffffff;
  z-index: 10;
  -webkit-transform: translateZ(0);
  align: center;
}

.dcm-mainscreen-tools-copycampaign-optionholder {
  width: 80%;
  align: center;
  margin: auto;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
}

.deactivatingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 4;
  background-color: #dddddd;
}

.deactivatingOverlayHigh {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 19;
  background-color: #dddddd;
}
/* ------------------------------------------------------- */
/* Stuff for the sender screen. */

.dcm-sender-conversation {
  width: 60%;
  float: left;
  margin: 0;
  height: 90%;
  background-color: #eeeeee;
}
.dcm-sender-conversation-top {
  width: 100%;
  height: 50%;
  background-color: #dddddd;
  overflow-y: scroll;
  border-style: solid;
  border-color: #aaaaaa;
  border-width: 0px 0px 1px 0px;
}
.dcm-sender-conversation-out {
  width: 58%;
  margin: 2% 2% 2% 38%;
  padding: 1%;
  border-radius: 10px;
  background-color: #0072c6;
  color: #ffffff;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
}
.dcm-sender-conversation-in {
  width: 58%;
  margin: 2% 38% 2% 2%;
  border-radius: 10px;
  padding: 1%;
  background-color: #eeeeee;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
}
.dcm-sender-conversation-status {
  width: 94%;
  margin: 2% 2% 2% 2%;
  border-radius: 10px;
  padding: 1%;
  color: #ffffff;
  background-color: #dd0000;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
  text-align: center;
}
.dcm-sender-phonelist {
  width: 40%;
  height: 90%;
  float: right;
  margin: 0;
  overflow-y: scroll;
}
.dcm-sender-phonelist-row {
  width: 100%;
  margin: 0;
  height: 24pt;
  line-height: 24pt;
  border-style: solid;
  border-color: #aaaaaa;
  border-width: 0px 0px 1px 0px;
  display: inline-block;
  vertical-align: middle;
  background-color: #ffffff;
  transition: background-color 200ms;
}
.dcm-sender-phonelist-row-selected {
  width: 100%;
  margin: 0;
  height: 24pt;
  line-height: 24pt;
  border-style: solid;
  border-color: #aaaaaa;
  border-width: 0px 0px 1px 0px;
  display: inline-block;
  vertical-align: middle;
  background-color: #dddddd;
  transition: background-color 200ms;
}
.dcm-sender-phonelist-row-terminating {
  width: 100%;
  margin: 0;
  height: 24pt;
  line-height: 24pt;
  border-style: solid;
  border-color: #aaaaaa;
  border-width: 0px 0px 1px 0px;
  display: inline-block;
  vertical-align: middle;
  background-color: #dd0000;
  color: #ffffff;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  text-align: center;
}
.dcm-sender-phonelist-row:hover {
  background-color: #eeeeee;
  cursor: pointer;
}
.dcm-sender-phonelist-row-name {
  width: 50%;
  float: left;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  margin-left: 5%;
  vertical-align: middle;
  height: 24pt;
  line-height: 24pt;
}
.dcm-sender-phonelist-row-phone {
  width: 40%;
  float: left;
  text-align: left;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  vertical-align: middle;
  height: 24pt;
  line-height: 24pt;
}
.dcm-sender-phonelist-row-light {
  width: 5%;
  float: left;
  position: relative;
  height: 24pt;
}
.dcm-sender-phonelist-row-light-green {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  left: 6px;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  border-color: #777777;
  background-color: #00ff00;
}
.dcm-sender-phonelist-row-light-green-blink {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 15px;
  left: 6px;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  border-color: #777777;
  background-color: #00ff00;
  animation: fadein 2s;
}
@keyframes fadein {
  0% {
    background-color: #ffffff;
    opacity: 0;
  }
  25% {
    background-color: #00ff00;
    opacity: 1;
  }
  75% {
    background-color: #ffffff;
    opacity: 0;
  }
  100% {
    background-color: #00ff00;
    opacity: 1;
  }
}

.dcm-sender-conversation-bottom {
  width: 100%;
  height: 45%;
  margin: 0;
  background-color: #eeeeee;
}
.dcm-sender-conversation-bottom-disabled {
  width: 100%;
  height: 45%;
  margin: 0;
  background-color: #eeeeee;
  pointer-events: none;
  opacity: 0.5;
}

.dcm-sender-conversation-bottom-script {
  width: 64%;
  height: 26%;
  margin: 2%;
  padding: 2%;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  background-color: #ffffff;
  float: left;
  border-style: solid;
  border-width: 2px;
  border-color: #dddddd;
}
.dcm-sender-conversation-bottom-script-disabled {
  width: 64%;
  height: 26%;
  margin: 2%;
  padding: 2%;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  background-color: #ffffff;
  float: left;
  border-style: solid;
  border-width: 2px;
  border-color: #dddddd;
  opacity: 0.5;
}
.dcm-sender-conversation-bottom-script:hover {
  border-color: #cccccc;
}
.dcm-sender-conversation-bottom-script:focus {
  outline: none;
  border-color: #999999;
}
.dcm-sender-conversation-bottom-script-adj {
  width: 22%;
  height: 30%;
  margin: 2%;
  padding: 0%;
  float: right;
}
.dcm-sender-conversation-bottom-prompt {
  width: 64%;
  height: 26%;
  margin: 2%;
  padding: 2%;
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  background-color: #ffffff;
  float: left;
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-color: #bbbbbb;
}
.dcm-sender-conversation-bottom-prompt:hover {
  border-color: #cccccc;
}
.dcm-sender-conversation-bottom-prompt:focus {
  outline: none;
  border-color: #999999;
}
.dcm-sender-conversation-bottom-prompt-adj {
  width: 22%;
  height: 30%;
  margin: 2%;
  padding: 0%;
  float: right;
}
.dcm-sender-conversation-terminate-adj {
  width: 22%;
  height: 12%;
  margin: 2%;
  padding: 0%;
  float: right;
}

.option-holder {
  background-color: #eeeeee;
  border-color: #aaaaaa;
  width: 93%;
  border-style: solid;
  border-width: 3px;
  font-size: 16pt;
  font-family: Ariel, sans-serif;
  border-radius: var(--button-corner-radius);
  margin: 2.5%;
  padding: 1%;
  display: table;
}

.option-holder-text {
  width: 62%;
  padding: 1% 2% 1% 2%;
  display: table-cell;
  vertical-align: middle;
}

.dcm-sender-passwordchange-modal {
  font-size: 12pt;
  font-family: Ariel, sans-serif;
  position: fixed;
  width: 400px;
  height: 300px;
  margin: -150px 0 0 -200px;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--button-corner-radius);
  border-color: #aaaaaa;
  background-color: #ffffff;
  z-index: 20;
  -webkit-transform: translateZ(0);
  text-align: center;
}
.dcm-sender-promptserver-modal {
  font-size: 24pt;
  font-family: Ariel, sans-serif;
  position: fixed;
  width: 400px;
  height: 300px;
  margin: -150px 0 0 -200px;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--button-corner-radius);
  border-color: #aaaaaa;
  background-color: #ffffff;
  z-index: 10;
  -webkit-transform: translateZ(0);
  text-align: center;
}

.dcm-sender-promptserver-modal-scroller {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 65%;
  border-top: 3px solid #aaaaaa;
  border-bottom: 3px solid #aaaaaa;
}

.dcm-sender-promptserver-row {
  font-size: 16pt;
  font-family: Ariel, sans-serif;
  width: 100%;
  padding: 1%;
  margin: 0;
  min-height: 24pt;
  line-height: 24pt;
  border-style: solid;
  border-color: #aaaaaa;
  border-width: 0px 0px 1px 0px;
  display: inline-block;
  vertical-align: middle;
  background-color: #ffffff;
  transition: background-color 200ms;
  text-align: left;
}
.dcm-sender-promptserver-row:hover {
  background-color: #eeeeee;
}
.dcm-sender-prompt-button {
  width: 10%;
  height: 10%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.dcm-checkbox {
  width: 16pt;
  height: 16pt;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #aaaaaa;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  transition: background-color 200ms;
}

.dcm-checkbox:checked {
  background-color: #444444;
}

input[type=number] {
  font-size: 1em;
  width: 8em; /* about right for 3 digits */
}

input[type=tel] {
  font-size: 1em;
  width: 12em; /* about right for 3 digits */
}

table td {
  /* vertical padding */
  padding-top: 0.5em;
}

table td + td {
  /* horizontal padding */
  padding-left: 0.5em;
}

table.closedq-answers,
table.flow-logic {
  width: 100%;
}

table td.action-button {
  /* shrink to fit */
  width: 0.1%;
  white-space: nowrap;
  vertical-align: middle;
}

/* .quotas-panel table tr:last-of-type,
table .summation {
  font-weight: bold;
} */

.quotas-panel table td:first-of-type:empty::before {
  content: "Total";
}

.closedq-answers td.value,
.flow-logic td.goto {
  width: 12%;
}

.conditional input {
  font-family: monospace;
  font-stretch: condensed;
}

.conditional input[disabled] {
  text-align: right;
}

.push-right {
  /* aligns to right within flexbox */
  margin-left: auto;
}

/* Billing tab */
.billing-table td {
  text-align: center;
  width: 15%
}
.billing-table td:first-child {
  width: 45%;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
.billing-status {
  max-width: 250px;
}
.billing-status-text {
  padding-top: 10px;
}
.billing-status-text::after {
  content: "";
  display: table;
  clear: both;
}
.billing-status-text dt, .billing-status-text dd {
  float: left;
  margin-bottom: 8px;
  display: inline;
}
.billing-status-text dt {
  width: 48%;
  clear: left;
}
.billing-status-text dd {
  margin-bottom: 8px;
  width: 35%;
  text-align: right;
  clear: right;
}